function formatMessage(message, messageType, isOutbound) {
  // Ensure the messageType is one of the allowed types
  const allowedTypes = [
    "text",
    "audio",
    "video",
    "location",
    "image",
    "custom",
  ];

  if (!allowedTypes.includes(messageType)) {
    throw new Error("Invalid message type");
  }

  // Structure the output based on the provided messageType
  switch (messageType) {
    case "text":
    case "audio":
    case "video":
    case "location":
    case "image":
    case "custom":
      return {
        type: messageType,
        [messageType]: message,
        timestamp: message.timestamp
          ? message.timestamp
          : new Date().toISOString(),
        direction: isOutbound ? "outbound" : "inbound",
      };
    default:
      // This shouldn't be reached if the input validation is working correctly
      throw new Error("Unexpected message type");
  }
}

export { formatMessage };
