import { useState, useCallback, useEffect } from "react";
import useSocket from "./useSocket";

import { useAuth0 } from "@auth0/auth0-react";
import { formatMessage } from "../utils";
import { getConversationsByRestaurantId } from "../api/conversations";

const NEW_CHAT_MESSAGE_EVENT = "message:incoming";
const SEND_MESSAGE_EVENT = "message:send-message";
const NEW_ORDER_EVENT = "order:new-order";
const NEW_ORDER_PICKUP_EVENT = "order:new-order:pickup-time";
const abortController = new AbortController();

// Conversation ID is: restaurantWA:customerWA to base 64

const useConversations = () => {
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null); // {id, messages: [{}]
  const [activeOrders, setActiveOrders] = useState([]);
  const { connect, on, off, socketRef, emit } = useSocket();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  // Load conversations from the API when the hook is used

  const addMessageToConversation = useCallback((conversation) => {
    setConversations((prevConversations) => {
      const existingConversation = prevConversations.find(
        (convo) => convo.id === conversation.id
      );
      if (existingConversation) {
        return prevConversations.map((convo) => {
          if (convo.id === conversation.id) {
            const result = {
              ...conversation,
              unreadMessages: true,
              messages: [
                ...convo.messages,
                formatMessage(
                  conversation.messages[0],
                  conversation.messages[0].type
                ),
              ],
            };
            console.log("result", result);
            return result;
          }
          return convo;
        });
      }
      return [...prevConversations, conversation];
    });
    setActiveConversation((activeConvo) => {
      if (activeConvo && activeConvo.id === conversation.id) {
        return {
          ...conversation,
          messages: [
            ...activeConvo.messages,
            formatMessage(
              conversation.messages[0],
              conversation.messages[0].type
            ),
          ],
        };
      }
      return activeConvo;
    });
  }, []);

  const addMessageToActiveConversation = useCallback((message, isOutbound) => {
    console.log(
      "addMessageToActiveConversation",
      formatMessage(message, "text")
    );
    setActiveConversation((activeConvo) => {
      if (activeConvo) {
        return {
          ...activeConvo,
          messages: [
            ...activeConvo.messages,
            formatMessage(message, "text", isOutbound),
          ],
        };
      }
      return activeConvo;
    });
  }, []);

  const sendMessage = (messageBody) => {
    emit(SEND_MESSAGE_EVENT, {
      message: messageBody,
      from: activeConversation.restaurantWhatsAppNumber,
      to: activeConversation.customerWhatsAppNumber,
    });
    addMessageToActiveConversation(messageBody, true);
  };

  const addOrderToActiveOrders = useCallback((order) => {
    console.log("addOrderToActiveOrders", order);
    setActiveOrders((prevOrders) => {
      // add order to the active Orders array
      // how can I avoid duplicates? should I use order id? if order id is in the array, can you update the fields?
      // if order id is not in the array, add it
      // if order id is in the array, update it
      // let existingOrder = prevOrders.find((o) => o.id === order.id);
      // if (existingOrder) {
      //   return prevOrders.map((o) => {
      //     if (o.id === order.id) {
      //       return order;
      //     }
      //     return o;
      //   });
      // }

      return [...prevOrders, order];
    });
  }, []);

  const addOrderPickupTime = useCallback((order) => {
    const orderId = order.orderId;
    const pickupTime = order.pickupTime;
    console.log("addOrderPickupTime", orderId, pickupTime);

    setActiveOrders((prevOrders) =>
      prevOrders.map((order) =>
        order._id === orderId
          ? { ...order, pickup: { ...order.pickup, time: pickupTime } }
          : order
      )
    );
  }, []);

  useEffect(() => {
    let ignore = false;
    const init = async () => {
      if (!isAuthenticated) return;
      console.log("Init Conversations");
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email",
        },
      });
      getConversationsByRestaurantId(accessToken).then((result) => {
        if (ignore) return;
        setConversations(result);
      });
    };
    init();
    return () => {
      ignore = true;
    };
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (!socketRef.current) return;
    on(NEW_CHAT_MESSAGE_EVENT, addMessageToConversation);
    return () => {
      off(NEW_CHAT_MESSAGE_EVENT, addMessageToConversation);
    };
  }, [on, off, socketRef, addMessageToConversation]);

  useEffect(() => {
    if (!socketRef.current) return;
    on(NEW_ORDER_EVENT, addOrderToActiveOrders);
    return () => {
      off(NEW_ORDER_EVENT, addOrderToActiveOrders);
    };
  }, [on, off, socketRef, addOrderToActiveOrders]);

  useEffect(() => {
    if (!socketRef.current) return;
    on(NEW_ORDER_PICKUP_EVENT, addOrderPickupTime);
    return () => {
      off(NEW_ORDER_PICKUP_EVENT, addOrderPickupTime);
    };
  });

  return {
    conversations,
    sendMessage,
    connect,
    activeConversation,
    setActiveConversation,
    activeOrders,
    setActiveOrders,
  };
};

export default useConversations;
